<ng-container>
  <h1>{{ "APP.AUTH.CREATE_ACCOUNT" | translate }}</h1>
  @if (!hasNoAccount) {
    @if (isAuthDialog && flow && flow !== "") {
      @if (flow === "PUBLISH") {
        <div class="subheader subheader-sm">
          {{ "APP.EVENT.CREATE_NEW_USER.BODY_DESCRIPTION" | translate }}
        </div>
      } @else if (flow === "EXIT_EVENT") {
        <div class="subheader subheader-sm">
          {{ "APP.EVENT.EXIT_FLOW.BODY_DESCRIPTION" | translate }}
        </div>
      }
    } @else {
      <div class="subheader subheader-sm">
        {{ "APP.AUTH.CREATE_ACCOUNT_SUBHEADER" | translate }}
      </div>
    }
  } @else if (hasNoAccount) {
    @if (selectedOption === "email") {
      <div class="subheader subheader-sm">
        {{ "APP.AUTH.ERRORS.USER_NOT_FOUND_1" | translate }}
        <br />
        {{ "APP.AUTH.ERRORS.USER_NOT_FOUND_2" | translate }}
        <span class="text-link" (click)="goToLogin()">
          {{ "APP.AUTH.ERRORS.USER_NOT_FOUND_3" | translate }}
        </span>
      </div>
    } @else {
      <div class="subheader subheader-sm">
        {{ "APP.AUTH.ERRORS.USER_NOT_FOUND_1_PHONE" | translate }}
        <br />
        {{ "APP.AUTH.ERRORS.USER_NOT_FOUND_2" | translate }}
        <span class="text-link" (click)="goToLogin()">
          {{ "APP.AUTH.ERRORS.USER_NOT_FOUND_3_PHONE" | translate }}
        </span>
      </div>
    }
  }

  <app-credentials-form-button-toggle
    [selectedOption]="selectedOption"
    (selectedOptionEvent)="selectOption($event)"
  >
  </app-credentials-form-button-toggle>

  <div class="form-380">
    @if (selectedOption === "email") {
      <app-sign-up-email-form
        [email]="email"
        [invitedCoHostEmail]="invitedCoHostEmail"
        (emailEntered)="onEmailEntered($event)"
      ></app-sign-up-email-form>
    } @else if (selectedOption === "phone") {
      <app-sign-up-phone-form
        [phone]="phone"
        [isAuthDialog]="isAuthDialog"
        (phoneEntered)="onPhoneEntered($event)"
        (toLogin)="goToLogin($event)"
      >
      </app-sign-up-phone-form>
    }

    <div class="auth-fallback-container">
      <span class="text-grey">{{
        "APP.AUTH.HAVE_AN_ACCOUNT" | translate
      }}</span>
      <span class="text-link" (click)="goToLogin()">
        {{ "APP.AUTH.LOGIN" | translate }}
      </span>
    </div>

    <!-- <div class="hr-text">
      <span>{{ "APP.OR" | translate }}</span>
    </div>

    <app-sso-buttons [isSignUp]="true"></app-sso-buttons>

    <app-h-captcha-info></app-h-captcha-info> -->
  </div>
</ng-container>
