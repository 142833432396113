import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { QuillEditorComponent, QuillModules, QuillService } from 'ngx-quill';
import { FormsModule } from '@angular/forms';
import { Event } from '@models/events/event.model';
import Embed from 'quill/blots/embed';
import Delta from 'quill-delta';
import { BlockEmbed } from 'quill/blots/block';
import { EventSection } from '@models/events/event-section.model';
import { CommonModule, isPlatformServer } from '@angular/common';
import { first } from 'rxjs';

export class SoftLineBreakBlot extends Embed {
  static override blotName = 'softbreak';
  static override tagName = 'br';
  static override className = 'softbreak';
}

export class DividerBlot extends BlockEmbed {
  static override blotName = 'divider';
  static override tagName = 'hr';
}

function brMatcher(node: Node, delta: Delta) {
  const newDelta = new Delta();
  newDelta.insert({ softbreak: true });
  return newDelta;
}

function removeStylesMatcher(node: Node, delta: Delta) {
  delta.forEach((e) => {
    if (e && e.attributes) {
      e.attributes['color'] = '';
      e.attributes['background'] = '';
    }
  });
  return delta;
}

@Component({
  selector: 'app-rich-text-editor',
  standalone: true,
  imports: [CommonModule, FormsModule, QuillEditorComponent],
  templateUrl: './rich-text-editor.component.html',
  styleUrl: './rich-text-editor.component.scss',
})
export class RichTextEditorComponent implements OnInit, AfterViewInit {
  @Input() adminView = false;
  @Input() valueToChange: any;
  showToolbar: boolean = false;
  @Input() event?: Event;
  @Input() section?: EventSection;
  quillInstance!: any;

  @Output() updateValue = new EventEmitter();
  @Output() updateSameParagraph = new EventEmitter();
  @ViewChild('quillContainer') quillContainer!: ElementRef;
  @ViewChild('quillEditor', { static: false }) quillEditor?: any;

  editorConfig: QuillModules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
      ['clean'],
      ['link'],
    ],
    keyboard: {
      bindings: {
        Enter: {
          key: 'Enter',
          shiftKey: true,
          handler: this.shiftEnterHandler,
        },
      },
    },
    clipboard: {
      matchVisual: false,
    },
  };

  isServer = false;

  constructor(
    private quillService: QuillService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this.isServer = isPlatformServer(platformId);
  }

  ngOnInit() {
    if (this.adminView) {
      this.editorConfig.toolbar = [
        ['bold', 'italic', 'underline'],
        [{ header: 1 }, { header: 2 }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ['clean'],
        ['link'],
      ];
    }
  }

  ngAfterViewInit() {
    if (this.quillEditor && !this.isServer) {
      this.quillEditor.onEditorCreated.subscribe((editor: any) => {
        this.quillInstance = editor;
        this.quillInstance.root.innerHTML = this.valueToChange;
        this.registerCustomModules();
      });
    }
  }
  onEditorBlur() {
    this.saveValue();
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: any) {
    const clickedInside = this.isDescendant(
      this.quillContainer.nativeElement,
      event.target as Node,
    );
    if (!clickedInside) {
      if (this.showToolbar) {
        this.saveValue();
        this.showToolbar = false;
      }
    }
  }

  registerCustomModules() {
    this.quillService
      .getQuill()
      .pipe(first())
      .subscribe((quill) => {
        quill.register(SoftLineBreakBlot);
        quill.register(DividerBlot);
      });
    const clipboardModule = this.quillInstance.getModule('clipboard');

    if (clipboardModule) {
      this.quillInstance.clipboard.addMatcher('br', brMatcher);
      this.quillInstance.clipboard.addMatcher(
        Node.ELEMENT_NODE,
        removeStylesMatcher,
      );
    } else {
      console.error('Quill clipboard module not available.');
    }
  }

  shiftEnterHandler(this: any, range: any) {
    const currentLeaf = this.quill.getLeaf(range.index)[0];
    const nextLeaf = this.quill.getLeaf(range.index + 1)[0];
    this.quill.insertEmbed(range.index, 'softbreak', true, 'user');
    if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
      this.quill.insertEmbed(range.index, 'softbreak', true, 'user');
    }
    this.quill.setSelection(range.index + 1, 'silent');
  }

  private isDescendant(parent: Node, child: Node): boolean {
    let node: Node | null = child;
    while (node !== null) {
      if (node === parent) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  }

  saveValue() {
    let htmlContent = '';
    if (this.valueToChange) {
      htmlContent = this.valueToChange
        .replaceAll('<p></p>', '<br>')
        .replaceAll('<p><br class="softbreak"></p>', '');
    }

    if (this.section) {
      // @ts-ignore
      this.section = {
        ...this.section,
        description: htmlContent,
      };
      this.updateValue.emit(this.section);
    } else {
      this.updateValue.emit({
        value: htmlContent,
      });
    }
  }
}
