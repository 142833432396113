import { Component, Output, EventEmitter, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SignUpEmailFormComponent } from '../sign-up-email-form/sign-up-email-form.component';
import { SsoButtonsComponent } from '@modules/auth/components/sso-buttons/sso-buttons.component';
import { Router } from '@angular/router';
import { AppRoutes } from 'app/routes';
import { HCaptchaInfoComponent } from '@modules/shared/components/h-captcha-info/h-captcha-info.component';
import { CredentialsFormButtonToggleComponent } from '@modules/shared/components/credentials-form-button-toggle/credentials-form-button-toggle.component';
import { SetPhoneStepComponent } from '@modules/sign-up/components/set-phone-step/set-phone-step.component';
import { SignUpPhoneFormComponent } from '@modules/sign-up/components/sign-up-phone-form/sign-up-phone-form.component';

@Component({
  selector: 'app-sign-up-step0',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SignUpEmailFormComponent,
    SsoButtonsComponent,
    HCaptchaInfoComponent,
    CredentialsFormButtonToggleComponent,
    SetPhoneStepComponent,
    SignUpPhoneFormComponent,
  ],
  templateUrl: './sign-up-step0.component.html',
  styleUrl: './sign-up-step0.component.scss',
})
export class SignUpStep0Component {
  @Input() flow?: string;
  @Input() isAuthDialog = false;
  @Input() selectedOption: string = 'phone';
  @Input() email?: string;
  @Input() phone?: string;
  @Input() hasNoAccount: boolean = false;
  @Input() invitedCoHostEmail?: string;

  @Output() selectOptionEvent: EventEmitter<string> = new EventEmitter();
  @Output() phoneEntered: EventEmitter<string> = new EventEmitter();
  @Output() emailEntered: EventEmitter<string> = new EventEmitter();

  @Output() toLogin: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router) {}

  selectOption(option: string) {
    this.selectOptionEvent.emit(option);
  }

  onPhoneEntered(phone: string) {
    this.phoneEntered.emit(phone);
  }

  onEmailEntered(email: string) {
    this.emailEntered.emit(email);
  }

  goToLogin(event?: any): void {
    if (this.isAuthDialog) {
      this.toLogin.emit(event);
    } else {
      this.router.navigate(['/', AppRoutes.Root.login], {
        queryParams: { option: this.selectedOption },
      });
    }
  }
}
