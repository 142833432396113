<div #quillContainer>
  <quill-editor
    #quillEditor
    [(ngModel)]="valueToChange"
    [modules]="editorConfig"
    [class.toolbar-visible]="showToolbar"
    (click)="showToolbar = true"
    (blur)="onEditorBlur()"
    [ngClass]="{
      'event-edit-textarea event-description body-font custom-quill-container':
        !adminView,
      'admin-quill-container': adminView
    }"
    [class.quill-focused]="showToolbar"
  ></quill-editor>
</div>
